import React from "react"

import Layout from "../components/global/layout"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SearchEngineOptimizaion title="404: Siden blev ikke fundet" />
    <section className="fourofour">
      <h1>Siden blev ikke fundet</h1>
      <p>Denne side blev desværre ikke fundet.</p>
      <Link aria-label="Tilbage til forsiden" to="/">
        Klik her for at komme tilbage til forsiden
      </Link>
    </section>
  </Layout>
)

export default NotFoundPage

import React from "react"
import PropTypes from "prop-types"

import Footer from "./footer"
import "../../styles/style.scss"
import MainHeader from "../header/MainHeader"

const Layout = ({ children, showFooter }) => {
  return (
    <>
      <MainHeader />
      <main className="mainContent">{children}</main>
      {showFooter !== false && <Footer />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

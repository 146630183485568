import React from "react"
import SoMe from "./SoMe"
import Logo from "../assets/logo.svg"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Divider } from "@chakra-ui/layout"

export default function Footer() {
  const { allSanityRecipes, allSanityPost } = useStaticQuery(graphql`
    query {
      allSanityRecipes(limit: 6, sort: { publishedAt: DESC }) {
        nodes {
          slug {
            current
          }
          title
        }
      }
      allSanityPost(limit: 6, sort: { publishedAt: DESC }) {
        nodes {
          slug {
            current
          }
          title
        }
      }
    }
  `)

  function returnLinks(links) {
    return (
      <div className="linkWrapper">
        {links.nodes.map(link => {
          return (
            <Link
              key={link.slug.current + link.title}
              to={"/" + link.slug.current + "/"}
            >
              <p>
                {link.title.slice(0, 25).length < 25
                  ? link.title
                  : link.title.slice(0, 25) + "..."}
              </p>
            </Link>
          )
        })}
      </div>
    )
  }

  return (
    <footer>
      <Divider />
      <div className="footer">
        <div className="footerSection">
          <div className="logo">
            <Logo />{" "}
          </div>
          <p>
            Måske lever du på et budget, måske er din hverdag travl, eller måske
            har du svært ved at finde på nye og spændende opskrifter.
            Gastrologik er stedet, hvor du lærer at forbinde gastronomi med
            logik.
          </p>
        </div>
        <div className="footerSection">
          <h4>Opskrifter</h4>
          {returnLinks(allSanityRecipes)}{" "}
        </div>
        <div className="footerSection">
          <h4>Opslag</h4>
          {returnLinks(allSanityPost)}
        </div>
        <div className="footerSection">
          <h4>Brugbare links</h4>
          <div className="linkWrapper">
            <Link to={"/alle-opskrifter/"}>
              <p>Alle opskrifter</p>
            </Link>
            <Link to={"/alle-opslag/"}>
              <p>Alle blogopslag</p>
            </Link>
            <Link to={"/ingredienser/"}>
              <p>Ingredienser</p>
            </Link>
            <Link to={"/indeks/"}>
              <p>Indeks</p>
            </Link>

            <Link to={"/kontakt/"}>
              {" "}
              <p>Kontakt</p>{" "}
            </Link>
            <Link to={"/om/"}>
              {" "}
              <p>Om</p>{" "}
            </Link>
          </div>
        </div>
      </div>
      <Divider />
      <div className="some">
        {" "}
        <SoMe />
      </div>{" "}
    </footer>
  )
}
